import { isArticleType, isQuestionsType } from "../shared/scribe";

const ScribeContentDisplay = ({ generatedContent, contentType }) => {
  if (isArticleType(contentType)) {
    return (
      <div
        className="scribe-generated-content"
        dangerouslySetInnerHTML={{
          __html: generatedContent,
        }}
      />
    );
  }

  if (isQuestionsType(contentType)) {
    return <pre>{generatedContent}</pre>;
  }

  return generatedContent;
};

export default ScribeContentDisplay;
