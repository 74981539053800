import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { Container, Row, Col, Form, Button, Spinner, Modal, CloseButton, Table } from 'react-bootstrap';
import axios from 'axios';
import SearchListTable from './search-list-table';
import Breadcrumbs from '../components/breadcrumbs';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Paginations from '../components/pagination';

const SettingsComponent = () => {
    const [showSpinner, setShowSpinner] = useState(false);
    const [courseId, setCourseId] = useState(0);
    const [courseName, setCourseName] = useState();
    const [course, setCourse] = useState();
    const [courseArray, setCourseArray] = useState([]);
    const [unitArray, setUnitArray] = useState([]);
    const [lessonArray, setLessonArray] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [unitId, setUnitId] = useState();
    const [unitTitle, setUnitTitle] = useState();
    const [query, setQuery] = useState();
    const [maxResult, setMaxResult] = useState('');
    const [lessonContent, setLessonContent] = useState('');
    const [lessonId, setLessonId] = useState('');
    const [lessonName, setLessonName] = useState('');
    const [paginationData, setPaginationData] = useState([]);

    const [settingss, setSettingss] = useState({});
    const [isChanged, setIsChanged] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [courseOptions, setCourseOptions] = useState([]);
    const [channelName, setChannelName] = useState('');
    const [editChannel, setEditChannel] = useState(false);

    const [prefferd, setprefferd] = useState(false);


    const [prefferdChannelList, setPrefferdChannelList] = useState([]);
    const [restrictedChannelList, setRestrictedChannelList] = useState([]);

    const [clickPage, setClickPage] = useState(1);

    const handleChannelChange = (e) => {
        setChannelName(e.target.value);
    };


    const handleCourseChange = (e) => {
        setSelectedCourses(e);
    };
    const handleShow = () => setShowModal(true);
    const handleClose = () => { setShowModal(false); setChannelName(''); setSelectedCourses([]); getPreffered(); getRestricted(); setEditChannel(false); }

    const url = `${process.env.REACT_APP_API_URL}/video-finder-search`;

    let searchId = localStorage.getItem('search_id') ? localStorage.getItem('search_id') : 0

    const handleScrolDown = () => {
        window.scroll({
            top: document.body.offsetHeight,
            left: 0,
            behavior: 'smooth',
        });
    }

    const handleDescriptionChange = (e) => {
        setLessonContent(e.target.value)
    };
    const handleReset = () => {
        axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?wstoken=' + process.env.REACT_APP_TOKEN + '&wsfunction=local_course_service_course_data&moodlewsrestformat=json')
            .then((response) => {
                setCourseArray(response.data.data);
                setQuery('');
                setCourseId(0);
                setCourseName('');
                setUnitId('');
                setUnitTitle('');
                setMaxResult('');
                setUnitArray([]);
                setCourse('');
                setLessonId('');
                setLessonContent('');
                setLessonArray([]);
                setLessonName('');
            })
    };



    useEffect(() => {
        // getSearchedList()
    }, [searchId])
    const getSearchedList = () => {
        axios.put(`${url}?search_id=${searchId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(response => {
                setTableData(response.data.data);
            })
            .catch(error => {
                console.log("error", error)
            })

    }



    useEffect(() => {
        axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?wstoken=' + process.env.REACT_APP_TOKEN + '&wsfunction=local_course_service_course_data&moodlewsrestformat=json')
            .then((response) => {
                setCourseArray(response.data.data);

                const options = response.data.data.map(course => ({
                    value: course.Course_ID,
                    label: course.Course_Name,
                }));

                setCourseOptions(options);
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);


    const handleSave = () => {
        if (settingss.maximum_result && settingss.new_search_prompt && settingss.replacement_search_prompt) {
            setShowSpinner(true);

            const params = {
                max_result: settingss.maximum_result,
                new_search_prompt: settingss.new_search_prompt,
                replacement_search_prompt: settingss.replacement_search_prompt,

            };


            const apiUrl = `${process.env.REACT_APP_API_URL}/video-finder-settings`;
            axios.post(apiUrl, params, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then(response => {
                setShowSpinner(false);
                console.log("success data", response.data.success)
                if (response.data.success) {
                    toast.success(response.data.message)
                }
                else {
                    toast.error(response.data.message)
                }
            })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });

        } else {
            toast.error('Please complete all fields - they are mandatory.')
        }
    }

    useEffect(() => {
        getSettings();
        // getPreffered();
        getRestricted();

    }, [])

    const getSettings = () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/video-finder-settings`;

        axios.get(apiUrl)
            .then(response => {
                setSettingss(response.data.data);
            })
            .catch(error => {
                console.log("error", error)
            })

    }

    const getPreffered = () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/video-finder-preferred-channel?page=` + clickPage;
        setShowSpinner(true)
        setPrefferdChannelList([]);
        axios.get(apiUrl)
            .then(response => {
                setPaginationData(response.data.page_data);
                setPrefferdChannelList(response.data.data);
                setShowSpinner(false);
            })
            .catch(error => {
                console.log("error", error)
            })
    }

    useEffect(() => {
        getPreffered();
    }, [clickPage])

    const getRestricted = () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/video-finder-restricted-channel`;

        axios.get(apiUrl)
            .then(response => {

                setRestrictedChannelList(response.data.data);
            })
            .catch(error => {
                console.log("error", error)
            })

    }


    const removePrefferd = (channel) => {


        const apiUrl = `${process.env.REACT_APP_API_URL}/video-finder-preferred-channel`;

        const params = {
            id: channel.id
        };
        axios.delete(apiUrl, { params })
            .then(response => {
                toast.success(response.data.message)
                getPreffered();
            })
            .catch(error => {
                console.log("error", error)
            })


    };

    const removeRestricted = (channel) => {


        const apiUrl = `${process.env.REACT_APP_API_URL}/video-finder-restricted-channel`;

        const params = {
            id: channel.id
        };
        axios.delete(apiUrl, { params })
            .then(response => {
                toast.success(response.data.message)
                getRestricted();

            })
            .catch(error => {
                console.log("error", error)
            })

    };

    const handleMaxChange = (e) => {
        const { value } = e.target;
        setSettingss({ ...settingss, maximum_result: value });
        setIsChanged(true);
    };

    const handleRelavancyChange = (e) => {
        const { value } = e.target;
        setSettingss({ ...settingss, new_search_prompt: value });
        setIsChanged(true);
    };
    const handleReplacementChange = (e) => {
        const { value } = e.target;
        setSettingss({ ...settingss, replacement_search_prompt: value });
        setIsChanged(true);
    };

    const handleSaveChannel = () => {
        setEditChannel(false);

        var apiUrl = "";
        var params = "";

        if (prefferd) {

            if (channelName && selectedCourses.length) {

                apiUrl = `${process.env.REACT_APP_API_URL}/video-finder-preferred-channel`;
                params = {
                    url: channelName,
                    courses: selectedCourses
                };

            } else {
                toast.error('Please complete all fields - they are mandatory.')
            }

        } else {
            apiUrl = `${process.env.REACT_APP_API_URL}/video-finder-restricted-channel`;
            params = {
                title: channelName,
            };
        }

        axios.post(apiUrl, params, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(response => {

            setChannelName('');
            setSelectedCourses([]);
            handleClose();
            if (response.data.success) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }

        })
            .catch(error => {
                console.log("error", error)
            })
    }

    const handlePrefferdChlick = () => {
        handleShow();
        setprefferd(true);
    }

    const handleRestrictedChlick = () => {
        handleShow();
        setprefferd(false);
    }

    const cellStyle = {
        maxWidth: '100px', /* Set your preferred max-width */
        whiteSpace: 'nowrap', /* Prevent text wrapping */
        overflow: 'hidden', /* Hide overflowing content */
        textOverflow: 'ellipsis', /* Show ellipsis for truncated content */
        position: 'relative',
    };

    const title = useMemo(() => {
        return [
            {
                title: "",
            },
            {
                title: "Courses",
            },
            {
                title: "Custom URL",
            },
            {
                title: "Description",
            },
            {
                title: "Region",
            },
            {
                title: "Title",
            },
            {
                title: "URL",
            },
            {
                title: "Subscribers",
            },
            {
                title: "Videos",
            },
            {
                title: "Views"
            },
            {
                title: ""
            },
        ]
    }, [])

    return (
        <Container>
            <Row>
                <Col xs="12">
                    <div className="search-form-container flex-1 py-3">
                        <Breadcrumbs />
                        <p className="section-title mb-2">Setting</p>
                        <div className="form-block mt-3">
                            <div className="d-flex gap-3 flex-wrap">
                                <Form style={{ width: "100%" }}>
                                    <Row>
                                        <Col md={3} className='text-start'>
                                            <label class="me-3">Max Result to Return</label>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="" className="mb-2" >
                                                <Form.Control type="number" value={settingss.maximum_result} onChange={handleMaxChange}
                                                    min="1"
                                                    max="25"
                                                    step="1" style={{ paddingLeft: "35px", width: "10%" }} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={3} className='text-start'>
                                            <label class="me-3">Relevancy Prompt</label>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="" className="mb-2" >
                                                <Form.Control as="textarea" placeholder="" style={{ height: '180px', width: '450px' }} value={settingss.new_search_prompt} onChange={handleRelavancyChange} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={3} className='text-start'>
                                            <label class="me-3">Replacement Prompt</label>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="" className="mb-2" >
                                                <Form.Control as="textarea" placeholder="" style={{ height: '180px', width: '450px' }} value={settingss.replacement_search_prompt} onChange={handleReplacementChange} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                                <Form style={{ width: "100%" }}>
                                    <Row>
                                        <Col md={3} className='text-start'>
                                            <label class="me-3">Preferred Channels</label>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="" className="mb-2 text-start" >
                                                <a href="#" onClick={handlePrefferdChlick}><i class="fas fa-plus" /> Add Channels</a>
                                            </Form.Group>
                                            <div className="d-flex flex-wrap row-gap-2 column-gap-3">
                                                {!!prefferdChannelList?.length &&
                                                    <>
                                                        <div className='saved-list-table' >
                                                            <Table responsive >
                                                                <thead>
                                                                    <tr>
                                                                        {title.map((val) => (
                                                                            <th>{val.title}</th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {prefferdChannelList?.map((cd) => (
                                                                        <tr key={cd.video_id + 'key'}>
                                                                            <td className="text-start" style={cellStyle}>
                                                                                <img width="40px" height="40px" src={cd.thumbnail} />
                                                                            </td>
                                                                            <td className="text-start" style={cellStyle}><a
                                                                                data-tooltip-id="my-tooltip"
                                                                                data-tooltip-content={cd.courses.map(c => c.label).join(', ')}
                                                                                data-tooltip-place="top"
                                                                            >{cd.courses.map(c => c.label).join(', ').substring(0, 10) + (cd.courses.map(c => c.label).join(', ') > 10 ? '...' : '')}
                                                                            </a>
                                                                            </td>
                                                                            <td style={cellStyle}><a
                                                                                data-tooltip-id="my-tooltip"
                                                                                data-tooltip-content={cd.custom_url}
                                                                                data-tooltip-place="top"
                                                                            >{cd.custom_url}
                                                                            </a>
                                                                            </td>
                                                                            <td className="text-start" style={cellStyle}><a
                                                                                data-tooltip-id="my-tooltip"
                                                                                data-tooltip-content={cd.description}
                                                                                data-tooltip-place="top"
                                                                            >{cd.description.substring(0, 10) + (cd.description.length > 10 ? '...' : '')}
                                                                            </a>
                                                                            </td>
                                                                            <td style={cellStyle}><a
                                                                                data-tooltip-id="my-tooltip"
                                                                                data-tooltip-content={cd.region}
                                                                                data-tooltip-place="top"
                                                                            >{cd.region}
                                                                            </a>
                                                                            </td>
                                                                            <td style={cellStyle}><a
                                                                                data-tooltip-id="my-tooltip"
                                                                                data-tooltip-content={cd.title}
                                                                                data-tooltip-place="top"
                                                                            >{cd.title}
                                                                            </a>
                                                                            </td>
                                                                            <td style={cellStyle}><a
                                                                                data-tooltip-id="my-tooltip"
                                                                                data-tooltip-content={cd.url}
                                                                                data-tooltip-place="top"
                                                                            >{cd.url}
                                                                            </a>
                                                                            </td>
                                                                            <td style={cellStyle}><a
                                                                                data-tooltip-id="my-tooltip"
                                                                                data-tooltip-content={cd.subscriber_count}
                                                                                data-tooltip-place="top"
                                                                            >{cd.subscriber_count}
                                                                            </a>
                                                                            </td>
                                                                            <td style={cellStyle}><a
                                                                                data-tooltip-id="my-tooltip"
                                                                                data-tooltip-content={cd.video_count}
                                                                                data-tooltip-place="top"
                                                                            >{cd.video_count}
                                                                            </a>
                                                                            </td>
                                                                            <td style={cellStyle}><a
                                                                                data-tooltip-id="my-tooltip"
                                                                                data-tooltip-content={cd.view_count}
                                                                                data-tooltip-place="top"
                                                                            >{cd.view_count}
                                                                            </a>
                                                                            </td>
                                                                            <td style={cellStyle}><a
                                                                                onClick={(e) => {
                                                                                    e.preventDefault(); // Prevent default link behavior
                                                                                    setEditChannel(cd);
                                                                                    setprefferd(true);
                                                                                    setShowModal(true);
                                                                                    setChannelName(cd.url);
                                                                                    setSelectedCourses(cd.courses);
                                                                                }}
                                                                            > <i className="fas fa-edit" />
                                                                            </a>
                                                                                <a
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault(); // Prevent default link behavior
                                                                                        const confirmDelete = window.confirm(`Are you sure you want to remove ${cd.title}?`);
                                                                                        if (confirmDelete) {
                                                                                            removePrefferd(cd);
                                                                                        }
                                                                                    }}
                                                                                > <i className="fas fa-trash" />
                                                                                </a></td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                        <Row>
                                                            <Col className="d-flex justify-content-start disabled">
                                                                Displaying {paginationData.page_size} of {paginationData.total_records} Records
                                                            </Col>
                                                            <Col className="d-flex justify-content-end">
                                                                <Paginations
                                                                    current_page={paginationData.current_page}
                                                                    page_size={paginationData.page_size}
                                                                    total_page={paginationData.total_page}
                                                                    total_records={paginationData.total_records}
                                                                    clicked_page={setClickPage}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs='12'>
                                                                <Button hidden={!showSpinner} variant="link" className="mt-2 mb-5 btn-spinner ms-0 text-decoration-none" disabled>
                                                                    <Spinner
                                                                        as="span"
                                                                        animation="grow"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                        className='me-1'
                                                                    />
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                }
                                                {/* {prefferdChannelList && prefferdChannelList.map((channel) => (
                                                    <p style={{ border: "1px solid #919191", borderRadius: "24px", padding: "8px" }}>
                                                        {channel.title}  <a href="#" onClick={(e) => {
                                                            e.preventDefault(); // Prevent default link behavior
                                                            const confirmDelete = window.confirm(`Are you sure you want to remove ${channel.title}?`);
                                                            if (confirmDelete) {
                                                                removePrefferd(channel);
                                                            }
                                                        }}>
                                                            <i className="fas fa-times" />
                                                        </a>
                                                    </p>
                                                ))} */}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={3} className='text-start'>
                                            <label class="me-3">Restricted Channels</label>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="" className="mb-2 text-start" >
                                                <a href="#" onClick={handleRestrictedChlick}><i class="fas fa-plus" /> Add Channels</a>
                                            </Form.Group>
                                            <div className="d-flex flex-wrap row-gap-2 column-gap-3">

                                                {restrictedChannelList && restrictedChannelList.map((channel) => (

                                                    <p style={{ border: "1px solid #919191", borderRadius: "24px", padding: "8px" }}>
                                                        {channel.title}  <a href="#" onClick={(e) => {
                                                            e.preventDefault(); // Prevent default link behavior
                                                            const confirmDelete = window.confirm(`Are you sure you want to remove ${channel.title}?`);
                                                            if (confirmDelete) {
                                                                removeRestricted(channel);
                                                            }
                                                        }}>
                                                            <i className="fas fa-times" />
                                                        </a>
                                                    </p>
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>

                                <Form style={{ width: "100%" }}>
                                    <Row>
                                        <Col md="3">

                                        </Col>
                                        <Col>
                                            <Form.Group controlId="maxResultToReturn" className="mb-2" >
                                                <div className='d-flex gap-3 mt-auto justify-content-start align-items-center'>
                                                    {/* <div>
                                                        <Button variant="outline-dark" className="" onClick={() => { handleReset() }} disabled={!courseId && !unitId && !query && !maxResult && !lessonContent && !lessonId}>
                                                            Clear
                                                        </Button>
                                                    </div> */}
                                                    <div>
                                                        <Button onClick={(e) => {
                                                            e.preventDefault(); // Prevent default link behavior
                                                            const confirmDelete = window.confirm(`Are you sure you want to save this changes?`);
                                                            if (confirmDelete) {
                                                                handleSave()
                                                            }
                                                        }}
                                                            variant="primary" type="button" className="" disabled={!isChanged}>
                                                            Save
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                                {/* <Form className="mb-4 d-flex justify-content-end gap-2" style={{ maxWidth: "200px", width: "100%" }}>
                                        <Col className='d-flex flex-column'>
                                            <Form.Group controlId="maxResultToReturn" className="mb-2" >
                                                <Form.Control type="text" value={maxResult} onChange={(e) => { checkMaxResult(e.target.value) }} autoComplete="off" placeholder='Max Results' />
                                            </Form.Group>
                                            <div className='d-flex gap-3 mt-auto'>
                                                <Col >
                                                    <Button variant="outline-dark" className="w-100" onClick={() => { handleReset() }} disabled={!courseId && !unitId && !query && !maxResult && !lessonContent && !lessonId}>
                                                        Clear
                                                    </Button>
                                                </Col>
                                                <Col >
                                                    <Button onClick={() => { handleClick() }} variant="primary" type="button" className="w-100">
                                                        Search
                                                    </Button>
                                                </Col>
                                            </div>
                                        </Col>
                                    </Form> */}
                            </div>
                            <Row>
                                <Col xs='12'>
                                    <Button hidden={!showSpinner} variant="link" className="mt-2 mb-5 btn-spinner ms-0 text-decoration-none" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className='me-1'
                                        />
                                        Saving...
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>


                <Col>

                    <Modal
                        show={showModal}
                        onHide={handleClose}
                        centered
                        size="lg"
                        backdrop="static" // Prevent modal from closing on backdrop click
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{editChannel ? "Edit" : "Add"} Channels</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col>
                                    <Form.Group controlId="formChannel">
                                        <Form.Control
                                            readOnly={editChannel}
                                            type="text"
                                            placeholder='Handler of the channel or any video url from the channel'
                                            value={channelName}
                                            onChange={handleChannelChange}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                {prefferd && <Col>
                                    <Form.Group controlId="formCourses">
                                        <Select
                                            isMulti
                                            name="courses"
                                            options={courseOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(e) => handleCourseChange(e)}
                                            placeholder="Select course"
                                            value={selectedCourses}
                                        />
                                    </Form.Group>
                                </Col>}
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose} style={{ backgroundColor: "#fff", color: "#1D3C6E" }}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={handleSaveChannel} style={{ backgroundColor: "#094FA3" }}>
                                {editChannel ? "Save" : "Add"}
                            </Button>
                        </Modal.Footer>
                    </Modal>


                </Col>
            </Row>
        </Container>
    );

}
export default SettingsComponent;