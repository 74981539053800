import { bulletArticlePrompt, matchingQuestionPrompt, mcqPrompt, paragraphArticlePrompt, seoArticlePrompt, shortAnswerQuestionPrompt, trueFalseQuestionPrompt } from "../constants/scribe-ai-prompts";

export const contentTypes = [
    { value: "seo_article", label: "SEO Optimized Article", prompt: seoArticlePrompt },
    { value: "article_para", label: "Article (paragraph)", prompt: paragraphArticlePrompt },
    { value: "article_bullet", label: "Article (bullet points)", prompt: bulletArticlePrompt },
    { value: "multichoice", label: "MCQ", prompt: mcqPrompt },
    { value: "true_or_false", label: "True/False Questions", prompt: trueFalseQuestionPrompt },
    { value: "matching", label: "Matching Questions", prompt: matchingQuestionPrompt },
    { value: "short_answer", label: "Short Answer Questions", prompt: shortAnswerQuestionPrompt },
];

export function isQuestionsType(contentType) {
    return contentType === "MCQ"
        || contentType === "multichoice"
        || contentType === "true_or_false"
        || contentType === "matching"
        || contentType === "short_answer";
}

export function isArticleType(contentType) {
    return contentType === "SEO Optimized Article" ||
        contentType === "Article" ||
        contentType === "article_bullet" ||
        contentType === "article_para" ||
        contentType === "seo_article";
}

export function isNonSeoArticleType(contentType) {
    return contentType === "Article" ||
        contentType === "article_bullet" ||
        contentType === "article_para";
}

export function isSeoArticleType(contentType) {
    return contentType === "SEO Optimized Article" ||
        contentType === "seo_article";
}

export function isContentTypeSimilar(contentType, contentType2) {
    if (!contentType || !contentType2) {
        return false;
    }

    return (isArticleType(contentType) && isArticleType(contentType2)) ||
        (isQuestionsType(contentType) && isQuestionsType(contentType2));

}