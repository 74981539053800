import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import Paginations from "../components/pagination";
import { toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";

const VideoGuideReport = () => {
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [showSpinner, setShowSpinner] = useState(false);

  const [status, setStatus] = useState("published");
  const [searchedStatus, setSearchedStatus] = useState("published");

  const loadItems = async () => {
    if (!status) return;
    setShowSpinner(true);
    setTableData([]);
    const url = `${process.env.REACT_APP_API_URL}/video_guide/list`;
    const params = new URLSearchParams({
      page,
      page_size: 10,
      queue_status: status ?? "",
    });
    try {
      const { data } = await axios.get(`${url}?${params.toString()}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const promise = data.data.map(
        (row) =>
          new Promise(async (resolve, reject) => {
            const response = await axios.post(
              "https://" +
                process.env.REACT_APP_HOST +
                "/webservice/rest/server.php?wstoken=" +
                process.env.REACT_APP_TOKEN +
                "&wsfunction=local_course_service_get_cul_name&moodlewsrestformat=json&lesson=0&course=" +
                row.course_id +
                "&unit=" +
                row.unit_id +
                "&page=" +
                row.activity_id
            );

            const data = JSON.parse(response.data.data);

            resolve({
              id: row.id,
              video_url: row.video_url,
              course_name: data.course_name,
              unit_name: data.course_name,
              lesson_name: data.lesson_name,
            });
          })
      );
      const tableRows = await Promise.all(promise);

      setSearchedStatus(status);
      setTableData(tableRows);
      setPaginationData(
        data.page_data ?? {
          current_page: 1,
          page_size: 0,
          total_page: 1,
          total_records: 0,
        }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    } finally {
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    loadItems();
  }, [page]);

  const [publishingVideo, setPublishingVideo] = useState([]);
  const publishVideo = useCallback((row, status) => {
    if (!row) return;
    setPublishingVideo((prev) => [...prev, row.id]);
    axios
      .put(`${process.env.REACT_APP_API_URL}/video_guide/chapter_generate`, {
        video_url: row.video_url,
        publish: status,
      })
      .then(() => {
        loadItems();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPublishingVideo((prev) => prev.filter((p) => p !== row.id));
      });
  }, []);

  return (
    <Container fluid className="p-4">
      <div style={{ display: "flex", gap: "8px", alignItems: "end" }}>
        <Form.Group className="text-left mb-2">
          <Form.Label style={{ width: "100%" }}>Status</Form.Label>
          <Form.Select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="" disabled>
              -- Please choose an option --
            </option>
            <option value={"published"}>Published</option>
            <option value={"InProgress"}>Processing</option>
            <option value={"Completed"}>Completed</option>
          </Form.Select>
        </Form.Group>
        <Button onClick={() => loadItems()} className="mb-2">
          Search
        </Button>
      </div>
      <Row>
        <Table responsive className="scribe-contents-table">
          <thead>
            <tr>
              <th>Video URL</th>
              <th>Course</th>
              <th>Unit</th>
              <th>Lesson</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((row) => (
              <tr key={row.id + "key"}>
                <td>{row.video_url}</td>
                <td>{row.course_name}</td>
                <td>{row.unit_name}</td>
                <td>{row.lesson_name}</td>
                <td>
                  {searchedStatus === "Completed" && (
                    <Button
                      variant="outline-dark"
                      onClick={() => {
                        publishVideo(row, true);
                      }}
                      style={{ padding: "2px 8px", minHeight: "24px" }}
                      disabled={publishingVideo.includes(row.id)}
                    >
                      Publish
                    </Button>
                  )}
                  {searchedStatus === "published" && (
                    <Button
                      variant="outline-dark"
                      onClick={() => {
                        publishVideo(row, false);
                      }}
                      style={{ padding: "2px 8px", minHeight: "24px" }}
                      disabled={publishingVideo.includes(row.id)}
                    >
                      Unpublish
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
      <Row>
        <Col className="d-flex justify-content-start disabled">
          Displaying {paginationData.page_size} of{" "}
          {paginationData.total_records} Records
        </Col>
        <Col className="d-flex justify-content-end">
          <Paginations
            current_page={paginationData.current_page}
            page_size={paginationData.page_size}
            total_page={paginationData.total_page}
            total_records={paginationData.total_records}
            clicked_page={setPage}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Button
            hidden={!showSpinner}
            variant="link"
            className="mt-2 mb-5 btn-spinner ms-0 text-decoration-none"
            disabled
          >
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-1"
            />
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default VideoGuideReport;
