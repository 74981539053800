import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import DeleteConfirmationModal from "./delete-confirmation-modal";
import VideoPopover from "./video-popover";
import { toast } from "react-toastify";

const TranscriptTable = ({ videoCode, onPlayClick, videoProgress, scrollRef, enableChapterRegeneration }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const [editMode, setEditMode] = useState([]);

    useEffect(() => {
        if (!videoCode) {
            setData([])
            return;
        }
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/video_guide/transcripts?VideoID=${videoCode}`)
            .then((response) => {
                if (response.data) {
                    setData(response.data.data.transcription);
                }
            })
            .catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }, [videoCode])

    const [saving, setSaving] = useState([])
    const onSave = useCallback((val, i) => {
        setSaving((prev) => [...prev, i])
        axios.post(`${process.env.REACT_APP_API_URL}/video_guide/transcripts`, {
            VideoID: videoCode,
            data: val,
        })
            .then((response) => {
                setEditMode((prev) => prev.filter(idx => idx != i))
                enableChapterRegeneration(true);
                toast.success("Successfully saved the transcript modification.");
            })
            .catch((err) => {
                console.log(err)
                toast.error("Error saving the transcript modification.");
            }).finally(() => {
                setSaving((prev) => prev.filter(idx => idx != i))
            })
    }, [videoCode])

    const [deleteConfirmationIndex, setDeleteConfirmationIndex] = useState();
    const [deleting, setDeleting] = useState(false)
    const onDelete = useCallback((i) => {
        setDeleting(true)
        const val = data[i];
        axios.delete(`${process.env.REACT_APP_API_URL}/video_guide/transcripts`, {
            data: {
                VideoID: videoCode,
                data: val,
            }
        })
            .then((response) => {
                setData(response.data.data.transcription)
                setDeleteConfirmationIndex()
                enableChapterRegeneration(true);
                toast.success("Successfully removed the transcript.");
            })
            .catch((err) => {
                console.log(err)
                toast.error("Error removing the transcript.");
            }).finally(() => {
                setDeleting(false)
            })
    }, [videoCode, data])

    const [videoPopoverTranscriptData, setVideoPopoverTranscriptData] = useState(null);
    const [videoPopoverTarget, setVideoPopoverTarget] = useState(null);

    return (
        <>
            <VideoPopover
                targetRef={videoPopoverTarget}
                startTime={videoPopoverTranscriptData?.start}
                endTime={videoPopoverTranscriptData?.end}
                videoId={videoCode}
                onClose={() => {
                    setVideoPopoverTarget(null);
                    setVideoPopoverTranscriptData(null);
                }}
            />
            <div className="d-flex flex-column">
                <Table responsive className="mt-2 transcript-list-table">
                    <thead>
                        <tr>
                            <th>Start (sec)</th>
                            <th>End (sec)</th>
                            <th>Transcript</th>
                            <th>Play</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading &&
                            <tr className="text-center">
                                <td colSpan={100}>
                                    Loading...
                                </td>
                            </tr>
                        }
                        {!loading && !data?.length &&
                            <tr className="text-center">
                                <td colSpan={100}>
                                    No transcripts found
                                </td>
                            </tr>
                        }
                        {!loading && data.map((val, i) => (
                            <tr style={{
                                background: videoProgress > val.start && videoProgress < val.end ? '#cfe2ff' : 'white'
                            }}>
                                <td>{Math.round(val.start * 100) / 100}</td>
                                <td>{Math.round(val.end * 100) / 100}</td>
                                <td>
                                    {editMode.includes(i) ?
                                        <textarea
                                            style={{ width: "100%" }}
                                            disabled={saving.includes(i)}
                                            value={val.text}
                                            onChange={(e) => {
                                                setData((prev) => {
                                                    const newData = [...prev]
                                                    newData[i] = {
                                                        ...newData[i],
                                                        text: e.target.value,
                                                    }
                                                    return newData;
                                                })
                                            }}
                                        />
                                        : val.text}
                                </td>
                                <td>
                                    <img
                                        style={{ cursor: 'pointer' }}
                                        src={"/images/play-circle.svg"}
                                        alt="play"
                                        onClick={(event) => {
                                            if (!editMode.length) {
                                                scrollRef.current.scrollIntoView();
                                                onPlayClick(Math.round(val.start * 100) / 100, Math.round(val.end * 100) / 100)
                                            } else {
                                                setVideoPopoverTarget(event.target);
                                                setVideoPopoverTranscriptData(val);
                                            }
                                        }}
                                    />
                                </td>
                                <td>
                                    {!editMode.includes(i) ?
                                        <img
                                            style={{ cursor: 'pointer' }}
                                            src={"/images/edit.svg"}
                                            alt="edit"
                                            onClick={() => setEditMode((prev) => [...prev, i])}
                                        /> :
                                        <img
                                            style={{ cursor: 'pointer' }}
                                            src={"/images/save.svg"}
                                            alt="save"
                                            onClick={() => saving.includes(i) ? {} : onSave(val, i)}
                                        />
                                    }
                                </td>
                                <td>
                                    <img
                                        style={{ cursor: 'pointer' }}
                                        src={"/images/delete-grey.svg"}
                                        alt="trash"
                                        onClick={() => setDeleteConfirmationIndex(i)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            {deleteConfirmationIndex >= 0 &&
                <DeleteConfirmationModal
                    loading={deleting}
                    onClose={() => setDeleteConfirmationIndex()}
                    onConfirm={() => onDelete(deleteConfirmationIndex)}
                />
            }
        </>
    );
};

export default TranscriptTable;
