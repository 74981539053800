import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { extractYouTubeVideoIds, toVideoUrl } from "./video-guide-settings";

const SummaryGeneration = () => {
  const [sourceType, _setSourceType] = useState("course-content");
  const setSourceType = useCallback((val) => {
    setData("");
    _setSourceType(val);
    setUrlInputValue("");
    setSelectedSource({
      lesson: "",
      course: "",
      unit: "",
      url: "",
    });
  }, []);

  const [urlInputValue, setUrlInputValue] = useState("");
  const [selectedSource, setSelectedSource] = useState({
    lesson: "",
    course: "",
    unit: "",
    url: "",
  });

  const [courseArray, setCourseArray] = useState([]);
  const [unitArray, setUnitArray] = useState([]);
  const [lessonArray, setLessonArray] = useState([]);
  const [urlArray, setUrlArray] = useState([]);

  const validateUnits = useCallback((data) => {
    const temp = data.slice();
    const val = temp.filter(
      (unit) => unit.name.trim() !== "" && unit.name != "."
    );
    return val;
  }, []);

  useEffect(() => {
    let url =
      "https://" +
      process.env.REACT_APP_HOST +
      "/webservice/rest/server.php?wstoken=" +
      process.env.REACT_APP_TOKEN +
      "&wsfunction=local_course_service_course_data&moodlewsrestformat=json";

    axios
      .post(url)
      .then((response) => {
        setCourseArray(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    if (!selectedSource.course) return;
    axios
      .post(
        "https://" +
          process.env.REACT_APP_HOST +
          "/webservice/rest/server.php?wstoken=" +
          process.env.REACT_APP_TOKEN +
          "&wsfunction=local_course_service_course_unit_data&moodlewsrestformat=json&course_id=" +
          selectedSource.course
      )
      .then((response) => {
        if (response.data) {
          const decodedData = JSON.parse(response.data.data);
          let units = decodedData[0].units;
          setUnitArray(validateUnits(units));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedSource.course]);
  useEffect(() => {
    if (!selectedSource.unit) return;
    axios
      .post(
        "https://" +
          process.env.REACT_APP_HOST +
          "/webservice/rest/server.php?wstoken=" +
          process.env.REACT_APP_TOKEN +
          "&wsfunction=local_course_service_unit_lessions_data&moodlewsrestformat=json&unit_id=" +
          selectedSource.unit
      )
      .then((response) => {
        if (response.data) {
          setLessonArray(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedSource.unit]);
  useEffect(() => {
    if (!selectedSource.lesson) return;
    const lesson = lessonArray.find(
      (l) => l.Id.toString() === selectedSource.lesson
    );
    if (!lesson) return;
    const urls = extractYouTubeVideoIds(lesson.Lession_Content);
    setUrlArray(urls.map((url, i) => ({
      url,
      name: `https://www.youtube.com/embed/${url}`,
    })));
  }, [selectedSource.lesson]);

  const [data, setData] = useState();

  const [loading, setLoading] = useState(false);
  const generateSummary = useCallback(async () => {
    const videoCode =
      sourceType === "course-content"
        ? selectedSource.url
        : extractYouTubeVideoIds(urlInputValue);

    setLoading(true);
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_API_URL + `/video-finder-transcript-summary`,
        { video_url: `https://www.youtube.com/embed/${videoCode}` }
      );
      setData(data?.message);
    } catch (err) {
      toast.error("Failed to generate summary");
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [sourceType, selectedSource, urlInputValue]);

  return (
    <>
      <Container className="my-3">
        <div className="d-flex gap-4 mb-2">
          <Form.Check
            type="radio"
            name="sourceType"
            checked={sourceType === "url"}
            onChange={(e) => setSourceType("url")}
            label="Direct URL"
          />
          <Form.Check
            type="radio"
            name="sourceType"
            checked={sourceType === "course-content"}
            onChange={(e) => setSourceType("course-content")}
            label="Lesson"
          />
        </div>
        <Row>
          {sourceType === "url" && (
            <Col md="6" lg="8s">
              <Form.Group
                controlId="url-textbox"
                className="d-flex align-items-center pb-2"
              >
                <Form.Control
                  type="text"
                  placeholder="Enter URL"
                  style={{ maxWidth: "100%" }}
                  value={urlInputValue}
                  onChange={(e) => setUrlInputValue(e.target.value)}
                />
              </Form.Group>
            </Col>
          )}
          {sourceType === "course-content" && (
            <>
              <Col md="6" lg="4">
                <Form.Group className="py-1" controlId="source">
                  <Form.Select
                    value={selectedSource.course}
                    onChange={(e) =>
                      setSelectedSource((s) => ({
                        ...s,
                        course: e.target.value,
                      }))
                    }
                  >
                    <option>Select Course</option>
                    {!!courseArray &&
                      courseArray.map((course) => (
                        <option key={course.Course_ID} value={course.Course_ID}>
                          {course.Course_Name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md="6" lg="4">
                <Form.Group className="py-1" controlId="source">
                  <Form.Select
                    value={selectedSource.unit}
                    onChange={(e) =>
                      setSelectedSource((s) => ({ ...s, unit: e.target.value }))
                    }
                  >
                    <option>Select Unit</option>
                    {!!unitArray &&
                      unitArray.map((unit) => (
                        <option key={unit.id} value={unit.id}>
                          {unit.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md="6" lg="4">
                <Form.Group className="py-1" controlId="source">
                  <Form.Select
                    value={selectedSource.lesson}
                    onChange={(e) =>
                      setSelectedSource((s) => ({
                        ...s,
                        lesson: e.target.value,
                      }))
                    }
                  >
                    <option>Select Lesson</option>
                    {!!lessonArray &&
                      lessonArray.map((lesson) => (
                        <option key={lesson.Id} value={lesson.Id}>
                          {lesson.Lession_Name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md="6" lg="4">
                <Form.Group className="py-1" controlId="source">
                  <Form.Select
                    value={selectedSource.url}
                    onChange={(e) =>
                      setSelectedSource((s) => ({ ...s, url: e.target.value }))
                    }
                  >
                    <option>Select Video</option>
                    {!!urlArray &&
                      urlArray.map((url) => (
                        <option key={url.url} value={url.url}>
                          {url.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </>
          )}
          <Col md="6" lg="4" style={{ display: "flex", gap: "8px" }}>
            <Button
              variant="primary"
              disabled={
                loading || sourceType === "course-content"
                  ? !selectedSource.url
                  : !urlInputValue
              }
              onClick={() => generateSummary()}
              style={{ height: "48px" }}
            >
              {loading ? (
                <>
                  <Spinner animation="border" size="sm" />
                  <span className="ms-2">Generating</span>
                </>
              ) : (
                "Generate"
              )}
            </Button>
          </Col>
        </Row>
        <div
          style={{
            textAlign: "left",
            marginTop: "20px",
            whiteSpace: "pre-wrap",
          }}
        >
          {data}
        </div>
      </Container>
    </>
  );
};

export default SummaryGeneration;
