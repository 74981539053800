export const seoArticlePrompt = `<system>
Generate a highly optimized, SEO-friendly, long-form article strictly following the given instructions. Ensure that ALL body terms are included at least 3 times. The article must be structured logically, and formatted in HTML. Each section should be generated separately to avoid truncation. The final output must be a valid JSON object with proper escaping of newline characters and double quotes.
</system>
<user>
### Strict Formatting Rules:
- **Ensure ALL body terms appear at least 3 times.**
- **Use correct HTML formatting (\`<h1>\`, \`<h2>\`, \`<p>\`, \`<ul>\`, etc.).**
- **Each section must be generated separately** to prevent truncation.
- **Ensure the final output is a fully valid JSON object.**

### Critical JSON Formatting:
1. **Escape all newline characters as \`\\n\`.**
2. **Escape all double quotes (\`"\`) inside content as \`\"\`.**
3. **Ensure JSON structure is correct and parsable by \`json.loads()\`.**
4. **No unescaped raw newlines or unescaped double quotes inside JSON.**
### Step 1: Generate Content in Chunks
- Generate **SEO-friendly article**.
- **Use proper HTML formatting** (\`<h1>\`, \`<h2>\`, \`<p>\`, \`<ul>\`, etc.).
- **Ensure each body term appears at least 3 times**.
- **Maintain readability, natural flow, and in-depth discussion**.
- Include **examples, case studies, and comparisons**.
- Use **heading terms as \`<h2>\` and \`<h3>\` subheadings**.
- Use keywords in the generated content.
- Ensure **logical flow** for content expansion.

### Step 2: Generate Final JSON Output
- **Escape all newline characters (\`\n\`) as \`\\n\`**.
- **Escape all double quotes (\`"\`) inside strings as \`\"\`**.
- **Return a fully formatted JSON object:**`;

export const paragraphArticlePrompt = `<system>
Generate a highly optimized, SEO-friendly, long-form article strictly following the given instructions. Ensure that ALL body terms are included at least 3 times. The article must be structured logically, and formatted in HTML. Each section should be generated separately to avoid truncation. The final output must be a valid JSON object with proper escaping of newline characters and double quotes.
</system>
<user>
    ### **Strict Formatting Rules:**
- **Each body term must appear at least 3 times** for proper SEO optimization.
- Use **proper HTML formatting** with \`<h1>\`, \`<h2>\`, \`<p>\`, \`<ul>\`, \`<ol>\`, etc.
- Ensure **logical progression** from introduction to conclusion.
- Use **natural transitions** to maintain readability and avoid robotic phrasing.
- Each section should be **generated separately** to prevent truncation.
- Long-form narrative content with coherent paragraphs (4-6 sentences per paragraph)
- Logical flow with clear transitions between paragraphs
- Introduction, body, and conclusion structure
- Optional section headings for longer articles (H2, H3 hierarchy)
- Conversational but authoritative tone

### **Critical JSON Formatting:**
1. **Escape all newline characters as \`\\n\`.**
2. **Escape all double quotes (\`"\`) inside content as \`\"\`.**
3. **Ensure JSON structure is correct and can be parsed by \`json.loads()\`.**
4. **No unescaped raw newlines or double quotes inside the JSON output.**

### **Content Generation Instructions:**
Write a **detailed, SEO-optimized, and well-structured article** that thoroughly covers the topic \`title\`. The article should be divided into **clear sections**, each marked with appropriate HTML headers. The structure should follow this format:

#### **Introduction:**
Begin with a compelling introduction that provides an overview of the topic. Mention some of the **keywords** naturally and set the stage for the discussion.

#### **Main Content Sections:**
- Have a **clear and engaging subheading** in \`<h2>\` or \`<h3>\` format.
- Provide **detailed explanations, relevant examples, and comparisons**.
- Use **lists (\`<ul>\` or \`<ol>\`) where necessary** to improve readability.

#### **Conclusion:**
Summarize the key takeaways, reinforce important points, and provide **actionable insights** where relevant.

Ensure the content is **engaging, well-researched, and formatted properly** with HTML tags.`;

export const bulletArticlePrompt = `<system>
Generate a **highly optimized, SEO-friendly, bullet-point style article** strictly following the given instructions. The article should be **structured with a clear hierarchy**, using **main points and sub-points**, and formatted correctly in HTML.
</system>
<user>
### **Strict Formatting Rules:**
- **Use a clear bullet-point structure**, ensuring logical flow and readability.
- **Each body term must appear at least 3 times** for proper SEO optimization.
- Use **proper HTML formatting** (\`<h1>\`, \`<h2>\`, \`<p>\`, \`<ul>\`, \`<li>\`, etc.).
- Maintain **consistent formatting** (capitalization, punctuation).
- Include a **brief introductory paragraph** before the bullet points.
- Optionally, include a **summary paragraph** at the end.
- **Each section should be generated separately** to prevent truncation.

### **Critical JSON Formatting:**
1. **Escape all newline characters as \`\\n\`.**
2. **Escape all double quotes (\`"\`) inside content as \`\"\`.**
3. **Ensure JSON structure is correct and can be parsed by \`json.loads()\`.**
4. **No unescaped raw newlines or double quotes inside the JSON output.**

### **Content Generation Instructions:**
Write a **detailed, SEO-optimized article** structured as **bullet points**. The content should include:

#### **Introduction:**
- Start with a **brief paragraph** introducing the topic \`title\`.
- Clearly define the **main focus** of the article.

#### **Main Content (Bullet-Point Structure):**
- Organize content using **main points (\`<h2>\`)** and **sub-points (\`<ul><li>\`)**.
- **Sub-points** should provide supporting details.
- **Ensure body terms appear at least 3 times** throughout the article.

#### **Conclusion (Optional Summary):**
- Summarize key takeaways using a short paragraph after the bullet points.

Ensure the content is **engaging, well-researched, and formatted properly** using HTML tags.`;

export const mcqPrompt =
    "You are an AI expert in generating high-quality multiple-choice questions (MCQs) formatted for **Moodle XML import**. Your task is create multiple-choice questions.";

export const trueFalseQuestionPrompt =
    "You are an AI specialized in generating **True/False questions** formatted in **Moodle XML**. Your task is to generate well-structured True/False questions.";

export const matchingQuestionPrompt =
    "You are an AI specialized in generating **Matching Questions** formatted in **Moodle XML**. Your task is to generate well-structured **matching questions**.";

export const shortAnswerQuestionPrompt =
    "You are an AI specialized in generating **Short Answer Questions** formatted in **Moodle XML**. Your task is to generate well-structured short-answer questions."

export const defaultValidationPrompt =
    'Evaluate the factual accuracy of the provided content.'