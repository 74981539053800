import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import VideoPopover from "./video-popover";
import { toast } from "react-toastify";

const ContentTable = ({ videoCode, onPlayClick, videoProgress, scrollRef, enableChapterRegeneration }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const [editMode, setEditMode] = useState([]);

    useEffect(() => {
        if (!videoCode) {
            setData([])
            return;
        }
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/video_guide/content?video_url=https://www.youtube.com/embed/${videoCode}`)
            .then((response) => {
                if (response.data?.data?.data?.length) {
                    setData(response.data.data.data);
                } else {
                    enableChapterRegeneration();
                }
            })
            .catch((err) => {
                enableChapterRegeneration();
            }).finally(() => {
                setLoading(false)
            })
    }, [videoCode])

    const [saving, setSaving] = useState([])
    const onSave = useCallback((val, key) => {
        setSaving((prev) => [...prev, key])
        axios.put(`${process.env.REACT_APP_API_URL}/video_guide/content`, {
            video_url: `https://www.youtube.com/embed/${videoCode}`,
            data: [{
                serial_number: val.serial_number,
                start_time: val.start_time,
                end_time: val.end_time,
                content: val.content,
            }],
        }).then((response) => {
            setEditMode((prev) => prev.filter(v => v != key))
            enableChapterRegeneration();
            toast.success("Successfully saved the chapter content modification.");
        }).catch((err) => {
            console.log(err)
            toast.error("Error saving the chapter content modification.");
        }).finally(() => {
            setSaving((prev) => prev.filter(v => v != key))
        })
    }, [videoCode])

    const [videoPopoverContentData, setVideoPopoverTranscriptData] = useState(null);
    const [videoPopoverTarget, setVideoPopoverTarget] = useState(null);

    return (
        <>
            <VideoPopover
                targetRef={videoPopoverTarget}
                startTime={videoPopoverContentData?.start_time}
                endTime={videoPopoverContentData?.end_time}
                videoId={videoCode}
                onClose={() => {
                    setVideoPopoverTarget(null);
                    setVideoPopoverTranscriptData(null);
                }}
            />
            <div className="d-flex flex-column">
                <Table responsive className="mt-2 transcript-list-table">
                    <thead>
                        <tr>
                            <th>S. No.</th>
                            <th>Start (sec)</th>
                            <th>End (sec)</th>
                            <th>Title</th>
                            <th>Content</th>
                            <th>Play</th>
                            <th>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading &&
                            <tr className="text-center">
                                <td colSpan={100}>
                                    Loading...
                                </td>
                            </tr>
                        }
                        {!loading && !data?.length &&
                            <tr className="text-center">
                                <td colSpan={100}>
                                    No contents found
                                </td>
                            </tr>
                        }
                        {!loading && data.map((val, i) => (
                            <tr style={{
                                background: videoProgress > val.start_time && videoProgress < val.end_time ? '#cfe2ff' : 'white'
                            }}>
                                <td style={{ width: "0px" }}>{val.serial_number}</td>
                                <td style={{ width: "80px" }}>
                                    {editMode.includes(i) ?
                                        <input
                                            type="number"
                                            style={{ width: "80px" }}
                                            disabled={saving.includes(i)}
                                            value={val.start_time}
                                            onChange={(e) => {
                                                setData((prev) => {
                                                    const newData = [...prev]
                                                    newData[i] = {
                                                        ...newData[i],
                                                        start_time: e.target.value,
                                                    }
                                                    return newData;
                                                })
                                            }}
                                        />
                                        : Math.round(val.start_time * 100) / 100}
                                </td>
                                <td style={{ width: "80px" }}>
                                    {editMode.includes(i) ?
                                        <input
                                            type="number"
                                            style={{ width: "80px" }}
                                            disabled={saving.includes(i)}
                                            value={val.end_time}
                                            onChange={(e) => {
                                                setData((prev) => {
                                                    const newData = [...prev]
                                                    newData[i] = {
                                                        ...newData[i],
                                                        end_time: e.target.value,
                                                    }
                                                    return newData;
                                                })
                                            }}
                                        />
                                        : Math.round(val.end_time * 100) / 100}
                                </td>
                                <td>{val.heading}</td>
                                <td style={{ width: "600px" }}>
                                    {editMode.includes(i) ?
                                        <textarea
                                            rows={5}
                                            style={{ width: "100%" }}
                                            disabled={saving.includes(i)}
                                            value={val.content}
                                            onChange={(e) => {
                                                setData((prev) => {
                                                    const newData = [...prev]
                                                    newData[i] = {
                                                        ...newData[i],
                                                        content: e.target.value,
                                                    }
                                                    return newData;
                                                })
                                            }}
                                        />
                                        : val.content}
                                </td>
                                <td>
                                    <img
                                        style={{ cursor: 'pointer' }}
                                        src={"/images/play-circle.svg"}
                                        alt="play"
                                        onClick={(event) => {
                                            if (!editMode.length) {
                                                scrollRef.current.scrollIntoView();
                                                onPlayClick(Math.round(val.start_time * 100) / 100, Math.round(val.end_time * 100) / 100)
                                            } else {
                                                setVideoPopoverTarget(event.target);
                                                setVideoPopoverTranscriptData(val);
                                            }
                                        }}
                                    />
                                </td>
                                <td>
                                    {!editMode.includes(i) ?
                                        <img
                                            style={{ cursor: 'pointer' }}
                                            src={"/images/edit.svg"}
                                            alt="edit"
                                            onClick={() => setEditMode((prev) => [...prev, i])}
                                        /> :
                                        <img
                                            style={{ cursor: 'pointer' }}
                                            src={"/images/save.svg"}
                                            alt="save"
                                            onClick={() => saving.includes(i) ? {} : onSave(val, i)}
                                        />
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </>
    );
};

export default ContentTable;
