import { Button, Modal } from "react-bootstrap";
import { isArticleType, isQuestionsType } from "../shared/scribe";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import ScribeContentDisplay from "./scribe-content-display";

const ScribeContentModal = ({
  show,
  onHide,
  data,
  isDownloading,
  onDownload,
  onContentSave,
}) => {
  const [savingContent, setSavingContent] = useState(false);
  const [contentEditMode, setContentEditMode] = useState(false);
  const [editContent, setEditContent] = useState(false);
  const saveContent = (id) => {
    setSavingContent(true);
    const url = `${process.env.REACT_APP_API_URL}/scribe-bot-publish`;
    axios
      .put(
        url,
        {
          id: id,
          content: editContent,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then(({ data }) => {
        onContentSave(editContent);
        toast.success("Content saved successfully");
      })
      .catch((error) => {
        console.error("Error saving content:", error);
        toast.error("Error saving content");
      })
      .finally(() => {
        setSavingContent(false);
        setContentEditMode(false);
      });
  };

  return (
    <Modal
      show={show && !!data}
      onHide={() => {
        setContentEditMode(false);
        onHide();
      }}
      centered
      dialogClassName="modal-90w"
      style={{ zIndex: 10000 }}
    >
      {!!data && (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Generated Content</Modal.Title>
            {data.status === "VALIDATION_COMPLETED" && (
              <>
                <Button
                  disabled={!!isDownloading}
                  onClick={() => {
                    onDownload(
                      isQuestionsType(data.content_type) ? "xml" : "txt"
                    );
                  }}
                  className="mb-2 ms-4"
                  variant="outline-dark"
                  style={{
                    height: "38px",
                    minHeight: "38px",
                    lineHeight: "14px",
                  }}
                >
                  {isDownloading
                    ? "Downloading"
                    : `Download as ${
                        isQuestionsType(data.content_type) ? "XML" : "TXT"
                      }`}
                </Button>
                <Button
                  disabled={!!savingContent}
                  onClick={() => {
                    if (contentEditMode) {
                      saveContent(data.id);
                    } else {
                      setEditContent(data.generated_content);
                      setContentEditMode(true);
                    }
                  }}
                  className="mb-2 ms-2"
                  variant={contentEditMode ? "primary" : "outline-dark"}
                  style={{
                    height: "38px",
                    minHeight: "38px",
                    lineHeight: "14px",
                  }}
                >
                  {contentEditMode
                    ? savingContent
                      ? "Saving Content"
                      : "Save Content"
                    : "Edit Content"}
                </Button>
              </>
            )}
          </Modal.Header>
          <Modal.Body style={{ overflowY: "auto", maxHeight: "500px" }}>
            {data.error ? (
              <div>Error: {data.error}</div>
            ) : data.status !== "VALIDATION_COMPLETED" ? (
              <div>Content generation in progress. Please wait</div>
            ) : (
              <>
                {isArticleType(data.content_type) && (
                  <>
                    <table
                      style={{
                        borderCollapse: "separate",
                        borderSpacing: "0 8px",
                      }}
                    >
                      <tbody>
                        <tr>
                          <th
                            style={{ minWidth: "200px", verticalAlign: "top" }}
                          >
                            Generated Title
                          </th>
                          <td>{data.generated_title}</td>
                        </tr>
                        <tr>
                          <th
                            style={{ minWidth: "200px", verticalAlign: "top" }}
                          >
                            Generated Keywords
                          </th>
                          <td>{data.generated_keywords}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      style={{
                        borderBottom: "1px solid #ddd",
                        margin: "24px 0",
                      }}
                    />
                  </>
                )}
                {contentEditMode ? (
                  <textarea
                    value={editContent}
                    onChange={(e) => setEditContent(e.target.value)}
                    style={{ width: "100%", height: "400px" }}
                    disabled={savingContent}
                  />
                ) : (
                  <ScribeContentDisplay
                    generatedContent={data.generated_content}
                    contentType={data.content_type}
                  />
                )}
              </>
            )}
          </Modal.Body>
        </>
      )}
    </Modal>
  );
};

export default ScribeContentModal;
