import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import Paginations from "../components/pagination";
import { toast } from "react-toastify";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import DeleteConfirmationModal from "../components/delete-confirmation-modal";
import ScribeContentDisplay from "../components/scribe-content-display";
import {
  contentTypes,
  isArticleType,
  isContentTypeSimilar,
  isQuestionsType,
} from "../shared/scribe";
import ScribeContentModal from "../components/scribe-content-modal";
import { type } from "@testing-library/user-event/dist/type";

const ScribeContents = () => {
  const [tableData, setTableData] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [page, setPage] = useState(1);
  const [showSpinner, setShowSpinner] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [viewId, setViewId] = useState();

  const [contentType, setContentType] = useState("");
  const [createdOn, setCreatedOn] = useState("");
  const [title, setTitle] = useState("");

  const loadItems = () => {
    setShowSpinner(true);
    setSelectedIds([]);
    setTableData([]);
    const url = `${process.env.REACT_APP_API_URL}/scribe-bot`;
    const params = new URLSearchParams({
      page,
      content_type: contentType ?? "",
      created_on: createdOn ?? "",
      title: title?.trim() ?? "",
    });
    axios
      .get(`${url}?${params.toString()}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        setPaginationData(
          response.data.page_data ?? {
            current_page: 1,
            page_size: 0,
            total_page: 1,
            total_records: 0,
          }
        );
        setTableData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data");
      })
      .finally(() => {
        setShowSpinner(false);
      });
  };

  const [triggerReload, setTriggerReload] = useState(false);
  useEffect(() => {
    loadItems();
    setTriggerReload(false);
  }, [triggerReload]);

  const [downloadingType, setDownloadingType] = useState();
  const downloadItems = (type) => {
    setDownloadingType(type);
    const url = `${process.env.REACT_APP_API_URL}/scribe-bot`;
    const params = new URLSearchParams(
      selectedIds?.length
        ? {
            ids: JSON.stringify(selectedIds),
            output_format: type,
          }
        : {
            output_format: type,
            content_type: contentType ?? "",
            created_on: createdOn ?? "",
            title: title?.trim() ?? "",
          }
    );
    axios
      .put(`${url}?${params.toString()}`, undefined, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(({ data }) => {
        const fileName = data.data;
        const fileUrl = `https://svhs-scribe-ai.s3.us-east-2.amazonaws.com/${fileName}`;
        fetch(fileUrl, { method: "GET" })
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          });
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
        toast.error("Error downloading file");
      })
      .finally(() => {
        setDownloadingType(false);
      });
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const deleteSelectedItems = () => {
    setDeleting(true);
    const url = `${process.env.REACT_APP_API_URL}/scribe-bot`;
    const params = new URLSearchParams({
      ids: JSON.stringify(selectedIds),
    });
    axios
      .delete(`${url}?${params.toString()}`, undefined, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(({ data }) => {
        loadItems();
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
        toast.error("Error downloading file");
      })
      .finally(() => {
        setDeleting(false);
        setDeleteModal(false);
      });
  };

  useEffect(() => {
    loadItems();
  }, [page]);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(tableData.map((row) => row.id));
    } else {
      setSelectedIds([]);
    }
  };

  const handleSelectRow = (id) => {
    setSelectedIds((prev) => {
      if (prev.includes(id)) {
        return prev.filter((selectedId) => selectedId !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const selectedData = useMemo(
    () => tableData?.find((d) => d.id === viewId),
    [tableData, viewId]
  );

  const [publishing, setPublishing] = useState();
  const publishPosts = (ids) => {
    setPublishing(ids.length === 1 ? ids[0] : true);
    const url = `${process.env.REACT_APP_API_URL}/scribe-bot-publish`;
    axios
      .post(
        url,
        { ids },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then(({ data }) => {
        loadItems();
      })
      .catch((error) => {
        console.error("Error publishing post:", error);
        toast.error("Error publishing post");
      })
      .finally(() => {
        setPublishing(false);
      });
  };

  const [retryingIds, setRetryingIds] = useState([]);
  const retry = (ids) => {
    setRetryingIds(ids);
    const url = `${process.env.REACT_APP_API_URL}/scribe-bot-publish`;
    const params = new URLSearchParams({ ids: `[${ids}]` });
    axios
      .get(`${url}?${params.toString()}`, undefined, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then(({ data }) => {
        loadItems();
      })
      .catch((error) => {
        console.error("Error retrying:", error);
        toast.error("Error retrying");
      })
      .finally(() => {
        setRetryingIds((prev) => prev.filter((id) => !ids.includes(id)));
      });
  };

  return (
    <Container fluid className="p-4">
      <Row className="align-items-end">
        <Col md="4">
          <Form.Group className="text-left mb-2">
            <Form.Label style={{ width: "100%" }}>Content type</Form.Label>
            <Form.Select
              value={contentType}
              onChange={(e) => setContentType(e.target.value)}
            >
              <option value="" disabled>
                -- Please choose an option --
              </option>
              {contentTypes.map((m) => (
                <option key={m.value} value={m.value}>
                  {m.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group className="text-left mb-2">
            <Form.Label style={{ width: "100%" }}>Title</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group className="text-left mb-2">
            <Form.Label style={{ width: "100%" }}>Created on</Form.Label>
            <Form.Control
              type="date"
              value={createdOn}
              onChange={(e) => setCreatedOn(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <div style={{ display: "flex", gap: "8px" }}>
        <Button
          onClick={() => {
            setPage(1);
            loadItems();
          }}
          className="mb-2"
        >
          Search
        </Button>
        {isQuestionsType(
          tableData?.find((d) => d.id === selectedIds[0])?.content_type
        ) ? (
          <Button
            disabled={!!downloadingType}
            onClick={() => downloadItems("xml")}
            className="mb-2"
          >
            {downloadingType === "xml" ? "Downloading" : "Download as XML"}
          </Button>
        ) : !!selectedIds.length ? (
          <Button
            disabled={!!downloadingType}
            onClick={() => downloadItems("csv")}
            className="mb-2"
          >
            {downloadingType === "csv" ? "Downloading" : "Download as CSV"}
          </Button>
        ) : (
          <></>
        )}
        <Button
          variant="outline-dark"
          onClick={() => {
            setContentType("");
            setCreatedOn("");
            setTitle("");
            setSelectedIds([]);
            setTriggerReload(true);
          }}
          className="mb-2"
        >
          Clear
        </Button>
        {!!selectedIds.length && (
          <>
            <Button
              variant="outline-dark"
              onClick={() => {
                setDeleteModal(true);
              }}
              className="mb-2"
              disabled={!!deleting}
            >
              {deleting ? "Deleting" : "Delete"}
            </Button>
            <Button
              variant="outline-dark"
              onClick={() => {
                publishPosts(selectedIds);
              }}
              className="mb-2"
              disabled={publishing == true}
            >
              {publishing ? "Publishing" : "Publish"}
            </Button>
          </>
        )}
      </div>
      <Row>
        <Table responsive className="scribe-contents-table">
          <thead>
            <tr>
              <th>
                {/* <Form.Check
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={selectedIds.length === tableData?.length}
                /> */}
              </th>
              <th>Title</th>
              <th>Content Type</th>
              <th>Created On</th>
              <th>Wordpress Post</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((row) => (
              <tr key={row.id + "key"}>
                <td>
                  <Form.Check
                    type="checkbox"
                    checked={selectedIds.includes(row.id)}
                    disabled={
                      selectedIds.length &&
                      !isContentTypeSimilar(
                        tableData.find((d) => d.id === selectedIds[0])
                          ?.content_type,
                        row.content_type
                      )
                    }
                    onChange={() => handleSelectRow(row.id)}
                  />
                </td>
                <td>{row.title}</td>
                <td>
                  {contentTypes.find((ct) => ct.value === row.content_type)
                    ?.label ?? row.content_type}
                </td>
                <td>{new Date(row.created_on).toLocaleString()}</td>
                <td>
                  {isArticleType(row.content_type) &&
                  row.status === "VALIDATION_COMPLETED" ? (
                    row.wordpress_site ? (
                      <a href={row.wordpress_site} target="_blank">
                        Open
                      </a>
                    ) : publishing === row.id ? (
                      "Publishing"
                    ) : (
                      <a href="#" onClick={() => publishPosts([row.id])}>
                        Publish
                      </a>
                    )
                  ) : (
                    <></>
                  )}
                </td>

                <td>
                  <div className="d-flex justify-content-end">
                    {["GENERATION_ERROR", "VALIDATION_ERROR"].includes(
                      row.status
                    ) ? (
                      <img
                        style={{
                          cursor: "pointer",
                          height: "18px",
                          marginRight: "4px",
                          opacity: retryingIds.includes(row.id) ? 0.6 : 1,
                        }}
                        src="/images/refresh-cw.svg"
                        alt=""
                        onClick={() =>
                          retryingIds.includes(row.id) ? {} : retry([row.id])
                        }
                      />
                    ) : (
                      <img
                        style={{ cursor: "pointer" }}
                        src="/images/eye.svg"
                        alt=""
                        onClick={() => setViewId(row.id)}
                      />
                    )}
                    <img
                      style={{ cursor: "pointer", marginLeft: "8px" }}
                      src={"/images/delete.svg"}
                      alt="trash"
                      onClick={() => {
                        setSelectedIds([row.id]);
                        setDeleteModal(true);
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
      <Row>
        <Col className="d-flex justify-content-start disabled">
          Displaying {paginationData.page_size} of{" "}
          {paginationData.total_records} Records
        </Col>
        <Col className="d-flex justify-content-end">
          <Paginations
            current_page={paginationData.current_page}
            page_size={paginationData.page_size}
            total_page={paginationData.total_page}
            total_records={paginationData.total_records}
            clicked_page={setPage}
          />
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Button
            hidden={!showSpinner}
            variant="link"
            className="mt-2 mb-5 btn-spinner ms-0 text-decoration-none"
            disabled
          >
            <Spinner
              as="span"
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-1"
            />
          </Button>
        </Col>
      </Row>
      <ScribeContentModal
        show={!!viewId}
        onHide={() => setViewId()}
        data={selectedData}
        onDownload={(type) => {
          setSelectedIds([viewId]);
          setTimeout(() => downloadItems(type));
        }}
        isDownloading={!!downloadingType}
        onContentSave={(editContent) =>
          setTableData((list) =>
            list.map((item) =>
              item.id === viewId
                ? { ...item, generated_content: editContent }
                : item
            )
          )
        }
      />
      {!!deleteModal && selectedIds.length > 0 && (
        <DeleteConfirmationModal
          loading={deleting}
          onClose={() => {
            setDeleteModal(false);
            setSelectedIds([]);
          }}
          onConfirm={() => deleteSelectedItems()}
        />
      )}
    </Container>
  );
};

export default ScribeContents;
