import React, { useState } from 'react';
import Header from './header';
import Sidebar from './sidebar';
import Footer from './footer';
import { useParams } from 'react-router-dom';
import { hasKeys } from '../shared/utils';
import VideoSearch from '../pages/video-search';
import ReplaceSearch from '../pages/replace-search-component';
import SavedListTable from '../pages/saved-video-list-table';
import Settings from '../pages/settings';
import VideoGuideDefaultSettings from '../pages/video-guide-default-settings';
import VideoGuideSettings from '../pages/video-guide-settings';
import ScribeContentGenerate from '../pages/scribe-content-generate';
import ScribeContents from '../pages/scribe-contents';
import SummaryGeneration from '../pages/summary-generation';
import VideoGuideReport from '../pages/video-guide-report';
const PageLayout = (props) => {

    const { location } = props;
    const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth <= 768 ? false : true);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768 ? true : false);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    const params = useParams();
    let divStyle = {};
    let className = '';
    if (location.pathname === '/home') {
        divStyle = {
            minHeight: 'calc(100vh - 64px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        };
        className = 'home-bg';
    }
    else {
        divStyle = {
            // padding: '4px 4px 5px', // Adjust padding as needed
            // maxWidth: '700px', // Set the desired height
            // height:'100%',
            // width:'100%',
            // backgroundSize: '99%', // Adjust the zoom level by changing the percentage
            // backgroundPosition: 'center',
            // backgroundRepeat: 'no-repeat',
            paddingBottom: '40px'
        };
        className = 'main-div';
    }
    return (
        <>
            <div className="app-container">
                <> <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} toggleSidebar={toggleSidebar} /></>
                <Sidebar params={params} location={location} isOpen={isSidebarOpen} onClose={toggleSidebar} toggleSidebar={toggleSidebar} setIsSidebarOpen={setIsSidebarOpen} setIsMobileView={setIsMobileView} isMobileView={isMobileView} />
                <div className={`main-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-close'}`}>
                    <div className={className} style={divStyle}>
                        {!hasKeys(params.action) && <div style={{ width: '70%' }}><img className='img-fluid' src={"/images/admin-bg.jpeg"}></img></div>}
                        {hasKeys(params) && hasKeys(params.action) && params.action == "search" && <VideoSearch />}
                        {hasKeys(params) && hasKeys(params.action) && params.action == "replace-search" && <ReplaceSearch />}
                        {hasKeys(params) && hasKeys(params.action) && params.action == "videos" && <SavedListTable />}
                        {hasKeys(params) && hasKeys(params.action) && params.action == "settings" && <Settings />}
                        {hasKeys(params) && hasKeys(params.action) && params.action == "video-guide-default-settings" && <VideoGuideDefaultSettings />}
                        {hasKeys(params) && hasKeys(params.action) && params.action == "video-guide-settings" && <VideoGuideSettings />}
                        {hasKeys(params) && hasKeys(params.action) && params.action == "video-guide-report" && <VideoGuideReport />}
                        {hasKeys(params) && hasKeys(params.action) && params.action == "scribe-content-generate" && <ScribeContentGenerate />}
                        {hasKeys(params) && hasKeys(params.action) && params.action == "scribe-contents" && <ScribeContents />}
                        {hasKeys(params) && hasKeys(params.action) && params.action == "summary-generation" && <SummaryGeneration />}
                    </div>
                </div>
                <Footer isOpen={isSidebarOpen} />
            </div>
        </>
    );
};

export default PageLayout;
